import React, { useState, useMemo, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SUBJECTS = [
    {
        value: 'Business',
        label: 'Business'
    },
    {
        value: 'Employee',
        label: 'Join the Team'
    },
    {
        value: 'Questions?',
        label: 'Questions?'
    },
    {
        value: 'Feedback',
        label: 'Feedback'
    }
]

function encode(data) {
    const formData = new FormData();

    for (const key of Object.keys(data)) {
        formData.append(key, data[key]);
    }

    return formData;
}

const ContactUs = () => {
    const [state, setState] = useState({
        subject: SUBJECTS[0].value
    })

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleAttachment = (e) => {
        setState({ ...state, [e.target.name]: e.target.files[0] })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // const form = e.target
        fetch('http://localhost:8888/.netlify/functions', {
            method: 'POST',
            body: encode({
                //   'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            // .then(() => navigate(form.getAttribute('action')))
            .then(() => alert('Sucesso'))
            .catch((error) => alert(error))
    }

    return (
        <Layout>
            <Seo title="Contact us" />

            <section className="py-20">
                <div className="flex flex-col px-3 md:px-8 mx-auto space-y-12 max-w-3xl xl:px-12">
                    {/* <div className="py-10 bg-white md:py-16"> */}
                    {/* <div className="px-10 mx-auto max-w-7xl md:px-16"> */}
                    {/* <div className="max-w-3xl mx-auto mb-10 md:mb-16"> */}
                    <h2 className="w-full text-2xl font-bold text-primary text-center sm:text-3xl md:text-4xl">Contact us</h2>
                    <p className="mb-5 text-lg text-gray-700 text md:text-left text-center mx-auto">
                        Fill out the form below and we'll get back to you as soon as possible.
                        If the matter is more urgent please try our alternative contacts.
                    </p>
                    {/* </div> */}
                    <form
                        className="grid max-w-3xl space-y-4 sm:grid-cols-2"
                        onSubmit={handleSubmit}
                    >
                        <div className="sm:col-span-2">
                            <label htmlFor="subject" className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Subject</label>
                            <select className="form-select apblock
							w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-primary"
                                aria-label="Subject select"
                                id="subject"
                                name="subject"
                                value={state.subject}
                                // defaultValue="Business"
                                // onChange={e => setSubject(e.target.value)}
                                onChange={handleChange}
                                required
                            >
                                {SUBJECTS.map(({ value, label }) => (
                                    <option key={value} value={value}>{label}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label htmlFor="firstName" className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">First name</label>
                            <input
                                id="firstName"
                                name="firstName"
                                className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-primary"
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="lastName" className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Last name</label>
                            <input
                                id="lastName"
                                name="lastName"
                                className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-primary"
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {state.subject === 'Business' && (
                            <div className="sm:col-span-2">
                                <label htmlFor="company" className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Company</label>
                                <input
                                    id="company"
                                    name="company"
                                    className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-primary"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        )}

                        {state.subject === 'Employee' && (
                            <div className="sm:col-span-2">
                                <label htmlFor="cv" className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Upload your CV</label>
                                <input
                                    id="cv"
                                    name="cv"
                                    className="appearence-none w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-primary"
                                    type="file"
                                    accept="application/pdf"
                                    onChange={handleAttachment}
                                    required
                                />
                            </div>
                        )}

                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Email</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-primary"
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                className="w-full h-64 px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-primary"
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>

                        <div className="flex items-center justify-between sm:col-span-2">
                            <button type="submit" className="inline-block px-8 py-3 text-sm font-semibold text-center text-white transition duration-100 bg-primary rounded-md outline-none hover:bg-primary active:bg-blue-700 ring-primary md:text-base">
                                Send Message
                            </button>
                        </div>
                    </form>
                    {/* <p className="max-w-3xl mx-auto mt-5 text-xs text-gray-400">
                        Please allow up to 24-48 hour response during the weekdays.
                    </p> */}
                </div>
            </section>


        </Layout>
    )
}

export default ContactUs
